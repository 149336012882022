import React from 'react';
import styled from 'styled-components';

import Header from "../components/Header";
import FooterSection from "../components/Sections/FooterSection";
import AboutSectionTitle from "../components/Sections/AboutSectionTitle";
import AboutSection2 from "../components/Sections/AboutSectionFounders";
import AboutSection3 from "../components/Sections/AboutSectionVision"




export default function MainPage() {
    return (
        <StyledPage>
            <Header />
            <AboutSectionTitle />
            <AboutSection2 />
            <AboutSection3 />
            <FooterSection />
        </StyledPage>
    );
}

const StyledPage = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #05070A;
`;