import React from 'react';
import logo from './logo.svg';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './App.css';
import MainPage from "./pages/main-page/MainPage";
import AboutPage from "./pages/AboutPage";
import ResourcesPage from "./pages/ResourcesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
    {
      path: "/about",
        element: <AboutPage />,
    },
    {
      path: "/resources",
        element: <ResourcesPage />,
    }
])

function App() {
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
