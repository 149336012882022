import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import OurProduct from '../../images/OurProduct.svg';

export default function AboutSection() {
    const [titleVisible, setTitleVisible] = useState(false);
    const [lettersToColor, setLettersToColor] = useState(0);
    const titleRef = useRef<HTMLDivElement>(null);
    const titleText =
        '"Real estate development is stuck in old ways. Our vision is to make building easier, faster, and more connected for everyone involved. We built Oblix Engine to shake things up, bringing AI-driven tools to streamline design, sales, and construction all in one place."';
    const totalLetters = titleText.length;


    useEffect(() => {
        // Add scroll event listener to calculate how many letters to color
        const handleScroll = () => {
            if (titleRef.current) {
                const rect = titleRef.current.getBoundingClientRect();
                const totalScrollRange = window.innerHeight + rect.height;
                const scrollProgress = Math.min(
                    Math.max((window.innerHeight - rect.top)*1.1 / totalScrollRange, 0),
                    1
                );
                const lettersCount = Math.floor(scrollProgress * totalLetters);
                setLettersToColor(lettersCount);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check in case the user doesn't scroll

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [totalLetters]);

    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <Subtitle>{'Our Vision'}</Subtitle>
                    <TitleFirstLine ref={titleRef} visible={titleVisible}>
                        {titleText.split('').map((letter, index) => (
                            <Letter key={index} colored={index < lettersToColor}>
                                {letter}
                            </Letter>
                        ))}
                    </TitleFirstLine>
                </TextWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    background-color: #05070A;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 250px;

    @media (max-width: 1233px) {
        padding-top: 150px;
        padding-bottom: 200px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    padding-top: 0;
    gap: 70px;
    width: 85%;

    @media (max-width: 1233px) {
        gap: 30px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    @media (max-width: 1233px) {
        max-width: 95%;
    }
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    font-size: 45px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: left;
    text-transform: uppercase;
    line-height: 1.2;

    @media (max-width: 1233px) {
        font-size: 23px;
    }
`;

const Letter = styled.span<{ colored: boolean }>`
    background: ${(props) =>
            props.colored ? 'linear-gradient(180deg, #1462F7, #1CB0F2)' : '#F9F9F9'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
    color: #cdd0d3;
    word-wrap: break-word;
    text-align: center;
    max-width: 60%;
    text-transform: capitalize;

    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;
    }
`;
