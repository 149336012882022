import React from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import Home from "../../images/Home.jpg";


export default function HomeSection() {
    return (
        <OBXStyledSection background={Home}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'THE AI REAL ESTATE'}</TitleFirstLine>
                    <TitleSecondLine>{'DEVELOPMENT ENGINE.'}</TitleSecondLine>
                    <Subtitle>{'Eliminate disconnected workflows and manual inefficiencies. Empower your team to design, ' +
                        'sell, and build seamlessly with the all-in-one platform for real estate development.'
                    }</Subtitle>
                </TextWrapper>
                <ButtonWrapper>
                    <OBXCTAButton text='Join Waitlist'/>
                </ButtonWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: url(${(props) => props.background}) no-repeat center center;;
    height: 100vh;
    padding-top: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding-left: 150px;
    padding-top: 20%;
    
    @media (max-width: 1233px) {
        align-items: flex-start;
        padding-left: 30px;
        padding-right: 20px;
        flex-direction: column;
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 740px;
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: left;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 31px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 1233px) {
        font-size: 31px;
    }
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    line-height: 30px;
    margin: 10px 0;
    color: #BEBEC0;
    word-wrap: break-word;
    text-align: left;
    
    @media (max-width: 1233px) {
        font-size: 16px;
        line-height: 17px;
    }
`;

const ButtonWrapper = styled.div`
    margin-left: 40px;
    justify-content: flex-start;
    
    @media (max-width: 1233px) {
        margin-left: 0;
        margin-top: 20px;
    }
`;