import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import OurProduct from '../../images/OurProduct.svg';
import checkicon from '../../images/checkicon.svg';
import Image1 from '../../images/Preview/Design1.jpg';
import Image2 from '../../images/Preview/Design2.png';
import Image3 from '../../images/Preview/Design3.jpg';
import Image4 from '../../images/Preview/Design4.png';

import Image5 from '../../images/Preview/Vis1.jpg';
import Image6 from '../../images/Preview/Vis2.jpg';
import Image7 from '../../images/Preview/Vis3.jpg';
import Image8 from '../../images/Preview/Vis4.jpg';
import Image9 from '../../images/Preview/Vis5.jpg';

export default function ProductSection() {
    const [titleVisible, setTitleVisible] = useState(false);
    const titleRef = useRef(null);
    const categories = [
        {
            name: 'Design and Coordination',
            title: 'O-Forge: Ultra-Fast 3D Modeling',
            points: [
                'Parametric Design, With A MASSIVE Asset Library',
                'Visualize Your Design In Real-Time With Realistic Site Context Using Google Tiles',
                'Seamlessly Collaborate And Coordinate Across Disciplines In Real-Time',
                'Efficiently Manage Large Master Plans With Different Building Types Within A Single Project'
            ],
            images: [Image1, Image2, Image3],
            learnMoreLink: '/design-and-coordination'
        },
        {
            name: 'Sales and Marketing',
            title: 'O-Lume: Interactive 3D selling',
            points: [
                'Instantly create Immersive 3D property tours, providing buyers with a fully interactive experience',
                'quickly generate photo-realistic renders, videos and 360 panoramas  ',
                'Seamless integration with sales data, marking available, reserved and sold units',
                'Easily deploy a digital sales interface for remote presentations, enabling online sales'
            ],
            images: [Image5, Image6, Image7, Image8, Image9],
            learnMoreLink: '/sales-and-marketing'
        },
        {
            name: 'Construction Management',
            title: 'O-Build: Let AI do the Managing',
            points: [
                'Use the OBX dashboard to centralize and monitor communication, tasks, inspection requests, and more',
                'AI vision generates daily unbiased construction reports from site images',
                'AiDA chatbot leverages full project documentation as its knowledge-base to answer any question instantly',
                'Track costs in real-time and ensure alignment with project budgets'
            ],
            images: [Image3, Image4, Image1],
            learnMoreLink: '/construction-management'
        },
        {
            name: 'Procurement  & Estimation',
            title: 'O-Build: Centralized Procurement',
            points: [
                'Instantly generate BOQs directly from your designs',
                'Leverage historical data to estimate unit prices with precision',
                'Track purchase orders, deliveries, and inventory in real-time from one platform',
                'Receive automatic notifications on long lead items to avoid delays'
            ],
            images: [Image4, Image1, Image2],
            learnMoreLink: '/procurement-and-estimation'
        }
    ];

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % selectedCategory.images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [selectedCategory]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTitleVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    return (
        <OBXStyledSection id="product-section">
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine ref={titleRef} visible={titleVisible}>
                        {'A NEW WAY TO DESIGN, SELL AND BUILD'}</TitleFirstLine>
                    <TitleSecondLine ref={titleRef} visible={titleVisible}>
                        {'WITH ONE SOFTWARE'}</TitleSecondLine>
                    <Subtitle>{"OBLIX is not one tool, it's an Engine."}</Subtitle>
                </TextWrapper>
                <ButtonWrapper>
                    <OBXCTAButton
                        text='Join Waitlist'
                        size={'small'}
                    />
                </ButtonWrapper>
                <CategoriesWrapper>
                    {categories.map((category, index) => (
                        <CategoryButton
                            key={index}
                            onClick={() => {
                                setSelectedCategory(category);
                                setCurrentImageIndex(0); // Reset image index when category changes
                            }}
                            isSelected={category.name === selectedCategory.name}
                        >
                            {category.name}
                        </CategoryButton>
                    ))}
                </CategoriesWrapper>
                <ContentSection>
                    <PointsWrapper>
                        <Title>{selectedCategory.title}</Title>
                        {selectedCategory.points.map((point, index) => (
                            <Point key={index}>{point}</Point>
                        ))}
                        <LearnMoreButton href={selectedCategory.learnMoreLink}>
                            Learn More →
                        </LearnMoreButton>
                    </PointsWrapper>
                    <ImageWrapper>
                        <Image src={selectedCategory.images[currentImageIndex]} alt="Selected category" />
                    </ImageWrapper>
                </ContentSection>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const LearnMoreButton = styled.a`
    font-size: 19px;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:hover {
        text-decoration: underline;
    }
`;
const OBXStyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${OurProduct}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    gap: 10px;
    width: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1<{ visible: boolean }>`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const Subtitle = styled.h2`
    font-size: 24px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 10px 0;
    color: #CDD0D3;
    word-wrap: break-word;
    text-align: center;
    max-width: 60%;
    text-transform: capitalize;
    
    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;

    }
`;

const ButtonWrapper = styled.div`
    margin: 20px 0;
`;

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0;
    
    @media (max-width: 1233px) {
        flex-wrap: wrap;
        gap: 5px;
    }
`;

const CategoryButton = styled.button<{ isSelected: boolean }>`
    position: relative;
    padding: 10px 20px;
    background: transparent;
    color: ${({ isSelected }) => (isSelected ? '#F9F9F9' : '#858B93')};
    font-size: 20px;
    cursor: pointer;
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    border: none;
    overflow: hidden;
    transition: transform 0.5s ease;

    @media (max-width: 1233px) {
        font-size: 12px;
        padding: 10px 12px;
    }

    &:hover {
        transform: scale(1.1);
    }

    ${({ isSelected }) =>
            isSelected &&
            `
        transform: scale(1.1);
    `}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 12px;
        padding: 3px; 
        background: ${({ isSelected }) =>
                isSelected ? 'linear-gradient(45deg, #1462F7, #1CB0F2)' : 'none'};
        -webkit-mask:
                linear-gradient(0deg, #fff 0%, #fff 100%) content-box,
                linear-gradient(0deg, #fff 0%, #fff 100%);
        mask:
                linear-gradient(0deg, #fff 0%, #fff 100%) content-box,
                linear-gradient(0deg, #fff 0%, #fff 100%);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        transform: scale(${({ isSelected }) => (isSelected ? 1 : 0.8)});
        transition: transform 0.5s ease;
    }
`;
const ImageWrapper = styled.div`
    width: 60%; 
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    overflow: hidden; 
    
    @media (max-width: 1233px) {
        width: 100%;
    }
`;

const ContentSection = styled.div`
    display: flex;
    align-items: flex-start; 
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    
    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 20px;
        ${ImageWrapper} {
            order: -1;
        }
    }
`;

const PointsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 50%; 
    
    @media (max-width: 1233px) {
        width: 100%;
        gap: 0px;
    }
`;

const Title = styled.h2`
    margin: 0;
    align-self: flex-start;
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    color: #F9F9F9;
    text-transform: capitalize;
    font-family: 'DM Sans', sans-serif;
    padding-bottom: 20px;
    
    @media (max-width: 1233px) {
        font-size: 20px;
        padding-bottom: 5px;
    }
`;

const Point = styled.p`
    display: flex;
    align-items: center;
    font-size: 19px;
    text-align: left;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    padding-right: 30px;
    
    @media (max-width: 1233px) {
        font-size: 14px;
        margin-bottom: 3px;
    }

    &::before {
        content: url(${checkicon});
        margin-right: 10px;
    }
`;

const Image = styled.img`
    width: 100%;
    max-height: 100%; 
    border: 5px solid #1462F7;
    border-radius: 20px;
    box-sizing: border-box;
    
    @media (max-width: 1233px) {
        border: 4px solid #1462F7;
        border-radius: 10px;
    }
`;
