import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tempLogo from '../../images/tempLogo.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const FooterSection = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('/');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setSelectedNavItem(location.pathname);
    }, [location.pathname]);

    const handleNavItemClick = (href: string) => {
        setSelectedNavItem(href);
        if (href === '#product') {
            if (location.pathname !== '/') {
                navigate('/');
                setTimeout(() => {
                    document.getElementById('product-section')?.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            } else {
                document.getElementById('product-section')?.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (href === '/' || href.startsWith('/')) {
            navigate(href);
            window.scrollTo(0, 0);
        }
    };

    return (
        <FooterMainContainer>
            <FooterContainer>
                <FooterTitle>Join our waiting list!</FooterTitle>
                <FooterSubtitle>Get Early Access: Sign Up for Our Exclusive Waitlist!</FooterSubtitle>
                <InputWrapper>
                    <EmailInput type="email" placeholder="Enter your email" />
                    <JoinButton>Join</JoinButton>
                </InputWrapper>
            </FooterContainer>
            <img src={tempLogo} alt="Logo" />
            <FooterNav>
                <NavItem
                    onClick={() => handleNavItemClick('/')}
                    isSelected={selectedNavItem === '/'}
                >
                    Home
                </NavItem>
                <NavItem
                    onClick={() => handleNavItemClick('/about')}
                    isSelected={selectedNavItem === '/about'}
                >
                    About Us
                </NavItem>
                <NavItem
                    onClick={() => handleNavItemClick('#product')}
                    isSelected={selectedNavItem === '#product'}
                >
                    Product
                </NavItem>
                <NavItem
                    onClick={() => handleNavItemClick('/resources')}
                    isSelected={selectedNavItem === '/resources'}
                >
                    Resources
                </NavItem>
            </FooterNav>
            <CopyRight>©2024 OBLIX Tech All Rights Reserved.</CopyRight>
        </FooterMainContainer>
    );
};

const FooterMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    gap: 20px;

    @media (max-width: 1233px) {
        margin-bottom: 30px;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 300px;
    border-radius: 23px;
    background: linear-gradient(45deg, #1462F7, #1CB0F2);
    text-align: center;

    @media (max-width: 1233px) {
        width: 90%;
        height: 218px;
    }
`;

const FooterTitle = styled.h2`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: -10px;

    @media (max-width: 1233px) {
        font-size: 24px;
    }
`;

const FooterSubtitle = styled.h3`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;

    @media (max-width: 1233px) {
        font-size: 13px;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: #282C30;

    @media (max-width: 1233px) {
        gap: 0px;
        padding: 5px;
    }
`;

const EmailInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    width: 250px;
    background: none;
    color: white;

    @media (max-width: 1233px) {
        font-size: 14px;
        width: 200px;
    }

    &::placeholder {
        color: #ccc;
    }
`;

const JoinButton = styled.button`
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 1233px) {
        font-size: 14px;
    }

    &:hover {
        background-color: #0056b3;
    }
`;

const FooterNav = styled.nav`
    display: flex;
    gap: 40px;
    margin-top: 20px;

    @media (max-width: 1233px) {
        gap: 25px;
    }
`;

const NavItem = styled.div<{ isSelected: boolean }>`
    color: ${props => (props.isSelected ? 'white' : '#858B93')};
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: 1233px) {
        font-size: 14px;
    }

    &:hover {
        color: white;
    }
`;

const CopyRight = styled.h3`
    color: #858B93;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
`;

export default FooterSection;
