import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';
import tempLogo from '../images/tempLogo.svg';

const Header = () => {
    const [isToolbarOpen, setIsToolbarOpen] = useState(false);
    const [selectedNavItem, setSelectedNavItem] = useState('/');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setSelectedNavItem(location.pathname);
    }, [location.pathname]);

    const toggleToolbar = () => {
        setIsToolbarOpen(!isToolbarOpen);
    };

    const handleNavItemClick = (href: string) => {
        setSelectedNavItem(href);
        if (href === '#product') {
            if (location.pathname !== '/') {
                navigate('/');
                setTimeout(() => {
                    document.getElementById('product-section')?.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            } else {
                document.getElementById('product-section')?.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (href === '/' || href.startsWith('/')) {
            navigate(href);
            window.scrollTo(0, 0);
        }
        if (isToolbarOpen) {
            setIsToolbarOpen(false);
        }
    };

    return (
        <HeaderContainer>
            <ContentWrapper>
                <Logo src={tempLogo} alt="Logo" />
                <Nav>
                    <NavItem
                        onClick={() => handleNavItemClick('/')}
                        isSelected={selectedNavItem === '/'}
                    >
                        Home
                    </NavItem>
                    <NavItem
                        onClick={() => handleNavItemClick('#product')}
                        isSelected={selectedNavItem === '#product'}
                    >
                        Product
                    </NavItem>
                    {/*about us*/}
                    <NavItem
                        onClick={() => handleNavItemClick('/about')}
                        isSelected={selectedNavItem === '/about'}
                    >
                        About Us
                    </NavItem>

                    <NavItem
                        onClick={() => handleNavItemClick('/resources')}
                        isSelected={selectedNavItem === '/resources'}
                    >
                        Resources
                    </NavItem>
                </Nav>
                <ToolbarButton onClick={toggleToolbar}>
                    {isToolbarOpen ? '✕' : '☰'}
                </ToolbarButton>
            </ContentWrapper>
            {isToolbarOpen && (
                <Overlay>
                    <MobileNav>
                        <NavItem
                            onClick={() => handleNavItemClick('/')}
                            isSelected={selectedNavItem === '/'}
                        >
                            Home
                        </NavItem>
                        <NavItem
                            onClick={() => handleNavItemClick('#product')}
                            isSelected={selectedNavItem === '#product'}
                        >
                            Product
                        </NavItem>
                        {/*about us*/}
                        <NavItem
                            onClick={() => handleNavItemClick('/about')}
                            isSelected={selectedNavItem === '/about'}
                        >
                            About Us
                        </NavItem>
                        <NavItem
                            onClick={() => handleNavItemClick('/resources')}
                            isSelected={selectedNavItem === '/resources'}
                        >
                            Resources
                        </NavItem>
                    </MobileNav>
                </Overlay>
            )}
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 20px;
    background: none;
    z-index: 1000;

    @media (max-width: 1233px) {
        padding: 10px 10px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 10px;
    padding: 10px 20px;
    background: rgba(5, 7, 10, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 50px;
    z-index: 1000;
    width: 520px;

    @media (max-width: 1233px) {
        width: 100%;
        justify-content: space-between;
        top: 0;
        border-radius: 0;
        padding: 10px 30px;
    }
`;

const Logo = styled.img`
    height: 40px;
`;

const Nav = styled.nav`
    display: flex;
    gap: 40px;

    @media (max-width: 1233px) {
        display: none;
    }
`;

const NavItem = styled.div<{ isSelected: boolean }>`
    color: ${props => props.isSelected ? 'white' : '#858B93'};
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: 1233px) {
        font-size: 25px;
        font-weight: 600;
    }
    &:hover {
        color: white;
    }
`;

const ToolbarButton = styled.button`
    display: none;
    background: none;
    border: none;
    color: #858B93;
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 1233px) {
        display: block;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #05070A;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 999;
    padding: 20px;
`;

const MobileNav = styled.nav`
    display: flex;
    flex-direction: column;
    background: rgba(5, 7, 10, 0.9);
    padding: 20px;
    border-radius: 10px;
    gap: 20px;
    margin-top: 80px;

    ${NavItem} {
        margin-bottom: 30px;
    }
`;

export default Header;