import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import FocusCardWrapper from '../FocusCardWrapper';
import CardBox from '../CardBox';
import OurProduct from '../../images/OurProduct.svg';
import dash1 from '../../images/dash1.png';
import dash2 from '../../images/dash2.png';
import dash3 from '../../images/dash3.png';

export default function DashSection() {
    const [titleVisible, setTitleVisible] = useState(false);
    const titleRef = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        const titleObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTitleVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (titleRef.current) titleObserver.observe(titleRef.current);

        return () => {
            if (titleRef.current) {
                titleObserver.unobserve(titleRef.current);
            }
            return undefined;
        };

    }, []);

    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine ref={titleRef} visible={titleVisible}>
                        {'obx dash'}
                    </TitleFirstLine>
                    <TitleSecondLine ref={titleRef} visible={titleVisible}>
                        {'monitor your projects from'}
                    </TitleSecondLine>
                    <TitleThirdLine ref={titleRef} visible={titleVisible}>
                        {'anywhere'}
                    </TitleThirdLine>
                </TextWrapper>
                <LearnMoreButton href={'/OBX-Dash'}>
                    Learn More →
                </LearnMoreButton>
                <FocusCardWrapper>
                    {[
                        { src: dash1, title: 'Your Entire Project Portfolio' },
                        { src: dash2, title: 'Real-Time Tracking and Management' },
                        { src: dash3, title: 'AI-Generated Construction Reports' },
                    ].map((image, index) => (
                        <CardBox key={index} focused={false} showBackground={false}>
                            <ImageTitle>{image.title}</ImageTitle>
                            <StyledImage src={image.src} alt={`OBX Dash mockup ${index + 1}`} />
                        </CardBox>
                    ))}
                </FocusCardWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 15px;
    width: 100%;

    @media (max-width: 1233px) {
        gap: 10px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;

    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1<{ visible: boolean }>`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleThirdLine = styled.h1<{ visible: boolean }>`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
        margin-bottom: 20px;
    }
`;

const LearnMoreButton = styled.a`
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 1233px) {
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
`;

const ImageTitle = styled.h2`
    position: absolute;
    top: 0px;
    left: 20px;
    right: 20px;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    z-index: 10;

    @media (max-width: 1233px) {
        background: none;
        border: none;
        pointer-events: none;
    }
`;

const StyledImage = styled.img`
    height: 597px;
    border-radius: 23px;
    margin-top: 60px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    z-index: 5;
`;
