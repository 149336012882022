import React from 'react';
import styled from 'styled-components';
import HomeSection from "../../components/Sections/HomeSection";
import DemoSection from "../../components/Sections/DemoSection";
import ProductSection from "../../components/Sections/ProductSection";
import DashSection from "../../components/Sections/DashSection";
import EditorSection from "../../components/Sections/EditorSection";
import PerformanceSection from "../../components/Sections/PerformanceSection";
import Header from "../../components/Header";
import FooterSection from "../../components/Sections/FooterSection";
import { ParallaxProvider } from 'react-scroll-parallax';



export default function MainPage() {
    return (
        <StyledPage>
            <Header />
            <HomeSection />
            <DemoSection />
            <ProductSection />
            <ParallaxProvider>
                <DashSection />
            </ParallaxProvider>
            <EditorSection />
            <PerformanceSection />
            <FooterSection />
        </StyledPage>
    );
}

const StyledPage = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #05070A;
`;