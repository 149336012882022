import styled, { css } from 'styled-components';

interface CardBoxProps {
    focused: boolean;
    showBackground?: boolean;
}

const CardBox = styled.div<CardBoxProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 327px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    ${({ showBackground }) =>
            showBackground
                    ? css`
                        background: linear-gradient(124.03deg, #171E26 0%, #05070A 100%);
                        border-radius: 23px;
                        border: 1px solid #171719;
                        padding-bottom: 80px;
                        gap: 24px;
                    `
                    : css`
                        background: none;
                        gap: 0px;
                    `}

    @media (max-width: 1233px) {
    transform: ${(props) => (props.focused ? 'scale(1.05)' : 'scale(0.9)')};
    opacity: ${(props) => (props.focused ? 1 : 0.5)};
    z-index: ${(props) => (props.focused ? 5 : 1)};
}
    
    @media (min-width: 1233px) 
    {
        &:hover {
            transform: scale(1.07);
        }
    }
`;

export default CardBox;
