import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import OurProduct from "../images/OurProduct.svg";
import Threads from "../images/Threads.svg";
import GPU from "../images/GPU.svg";
import Buildings from "../images/Buildings.svg";
import OBXCTAButton from "../components/OBXCTAButton";
import Header from "../components/Header";
import FooterSection from "../components/Sections/FooterSection";


export default function ResourcesPage() {

    return (
        <StyledPage>
            <Header />
            <OBXStyledSection background={OurProduct}>
                <TextWrapper>
                    <TitleFirstLine>
                        {'coming'}
                    </TitleFirstLine>
                    <TitleSecondLine>
                        {'soon'}
                    </TitleSecondLine>
                </TextWrapper>

            </OBXStyledSection>
            <FooterSection />
        </StyledPage>
    );
}

const StyledPage = styled.div`
overflow-y: auto;
overflow-x: hidden;
background-color: #05070A;
`;

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 350px;
    

    @media (max-width: 1233px) {
        padding-bottom: 250px;
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    gap: 20px;

    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 0px;
    }
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;


