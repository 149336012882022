import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import OurProduct from '../../images/OurProduct.svg';
import Raph from '../../images/Raph.png';
import Nour from '../../images/Noureldin.png';
import LinkedIn from '../../images/LinkeIn.svg';
import Twitter from '../../images/Xtwi.svg';
import Arrow from '../../images/ArrowRight.svg';
import CardBox from '../CardBox';
import FocusCardWrapper from '../FocusCardWrapper';

export default function AboutSection() {
    const [titleVisible, setTitleVisible] = useState(false);
    const titleRef = useRef(null);

    useEffect(() => {
        // Show the title with intersection observer
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTitleVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleWrapper>
                        <TitleFirstLine ref={titleRef} visible={titleVisible}>
                            {'our'}
                        </TitleFirstLine>
                        <TitleSecondLine ref={titleRef} visible={titleVisible}>
                            {'founders'}
                        </TitleSecondLine>
                    </TitleWrapper>
                    <Subtitle>
                        {
                            'With first-hand insight into the challenges of real estate development, we created Oblix Engine to redefine how teams design, build, and collaborate seamlessly.'
                        }
                    </Subtitle>
                </TextWrapper>
                <FocusCardWrapper gapMobile={'30px'} >
                    {[
                        {
                            src: Raph,
                            title: 'Raphael Riad',
                            subtitle: 'CEO',
                            linkedinUrl: 'https://www.linkedin.com/in/raphael-riad/',
                            twitterUrl: 'https://x.com/RiadRaphael',
                            backContent:
                                'With a background in engineering and hands-on experience in large-scale commercial and residential real estate, Raphael is both a technical founder and industry expert. His work as a fitout contractor for top global brands fuels his vision to reshape the industry through Oblix Engine.',
                        },
                        {
                            src: Nour,
                            title: 'Ahmed Noureldin',
                            subtitle: 'CTO',
                            linkedinUrl: 'https://www.linkedin.com/in/ahmed-noureldin-740292200/',
                            twitterUrl: '',
                            backContent:
                                'An ex-Amazon engineer with a passion for the intricacies of low-level software, Ahmed took on the ambitious challenge of reimagining real estate development. For almost two years, he’s been dedicated to building Oblix Engine\'s powerful, transformative technology from the ground up.',
                        },
                    ].map((image, index) => (
                        <CardBox
                            key={index} focused={false}
                            showBackground={false}
                        >
                            <ImageBox
                            key={index}
                            src={image.src}
                            title={image.title}
                            subtitle={image.subtitle}
                            backContent={image.backContent}
                            linkedinUrl={image.linkedinUrl}
                            twitterUrl={image.twitterUrl}
                        />
                        </CardBox>
                    ))}
                </FocusCardWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

interface ImageBoxProps {
    src: string;
    title: string;
    subtitle: string;
    backContent: string;
    linkedinUrl: string;
    twitterUrl: string;

}

const ImageBox: React.FC<ImageBoxProps> = ({ src, title, subtitle, backContent, linkedinUrl, twitterUrl }) => {
    const [flipped, setFlipped] = useState(false);

    const handleClick = () => {
        setFlipped(!flipped);
    };

    return (
        <StyledImageBox onClick={handleClick}>
            <ImageBoxInner flipped={flipped}>
                <ImageBoxFront>
                    <StyledImage src={src} alt={title}/>
                    <ImageTitle>{title}</ImageTitle>
                    <ImageSubtitle>{subtitle}</ImageSubtitle>
                    <IconWrapper>
                        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                            <Icon src={LinkedIn} alt="LinkedIn"/>
                        </a>
                        <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                            <Icon src={Twitter} alt="Twitter"/>
                        </a>
                    </IconWrapper>
                    <ArrowIcon src={Arrow} alt="Arrow"/> {/* Add the arrow icon */}
                </ImageBoxFront>
                <ImageBoxBack>
                    <BackContent>
                        <p>{backContent}</p>
                    </BackContent>
                </ImageBoxBack>
            </ImageBoxInner>
        </StyledImageBox>
    );
};

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #05070A;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 200px;

    @media (max-width: 1233px) {
        padding-bottom: 0px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 70px;
    width: 85%;

    @media (max-width: 1233px) {
        gap: 30px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    background: #f9f9f9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1<{ visible: boolean }>`
    background: linear-gradient(180deg, #1462f7, #1cb0f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
    color: #cdd0d3;
    word-wrap: break-word;
    text-align: center;
    max-width: 60%;
    text-transform: capitalize;

    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 55px;
    padding-top: 0px;

    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 40px;
    }
`;

// Styled components for the flip effect
const StyledImageBox = styled.div`
    width: 301px;
    height: 407px;
    perspective: 1000px;
    cursor: pointer;
    position: relative;

    @media (max-width: 1233px) {
        height: 407px;
    }
`;

const ImageBoxInner = styled.div<{ flipped: boolean }>`
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform-origin: center center;
    transform: ${(props) => (props.flipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
    position: relative;

    @media (max-width: 1233px) {
        height: 407px;
    }

`;

const ImageBoxFace = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 23px;
    border: 1px solid #171719;
    top: 0;
    left: 0;
    box-sizing: border-box;

    @media (max-width: 1233px) {
        height: 407px;
    }

`;

const ImageBoxFront = styled(ImageBoxFace)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(124.03deg, #171e26 0%, #05070a 100%);
    padding-bottom: 10px;
    position: relative;
    overflow: visible;
`;

const ImageBoxBack = styled(ImageBoxFace)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(124.03deg, #05070a 0%, #171e26 100%);
    padding: 20px;
    transform: rotateY(180deg);
`;

const StyledImage = styled.img`
    width: 100%;
    border-radius: 23px 23px 0 0;
`;

const ImageTitle = styled.h2`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin: 20px 0 0 0;
`;

const ImageSubtitle = styled.h3`
    color: white;
    font-family: 'DM Sans', sans-serif;
    max-width: 80%;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 31px;
    margin: 5px 0 0 0;
`;

const BackContent = styled.div`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    padding: 20px;
    overflow: auto;

    p {
        margin: 0;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
`;

const Icon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

const ArrowIcon = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0.7;
`;