import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import OurProduct from '../../images/OurProduct.svg';

export default function AboutSectionTitle() {
    const [titleVisible, setTitleVisible] = useState(false);
    const titleRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTitleVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);


    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine ref={titleRef} visible={titleVisible}>
                        {'Transforming Real Estate'}
                    </TitleFirstLine>
                    <TitleSecondLine ref={titleRef} visible={titleVisible}>
                        {'Development with AI'}
                    </TitleSecondLine>
                    <Subtitle>
                        {
                            "From design to construction, streamline your workflow with an intuitive, AI-powered platform built for real estate innovation. "                       }
                    </Subtitle>
                </TextWrapper>
                <ButtonWrapper>
                    <OBXCTAButton text="Join Waitlist" size={'small'} />
                </ButtonWrapper>

            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 450px;
    padding-bottom: 250px;
    @media (max-width: 1233px) {
        padding-top: 250px;
        padding-bottom: 70px;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    padding-top: 0;
    gap: 70px;
    width: 85%;

    @media (max-width: 1233px) {
        gap: 30px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 85%;
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: left;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1<{ visible: boolean }>`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
    color: #cdd0d3;
    word-wrap: break-word;
    text-align: left;
    max-width: 60%;
    text-transform: capitalize;

    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;
    }
`;

const ButtonWrapper = styled.div`
    justify-content: flex-start;
`;
