import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from '../OBXCTAButton';
import FocusCardWrapper from '../FocusCardWrapper';
import CardBox from '../CardBox';
import OurProduct from '../../images/OurProduct.svg';
import Threads from '../../images/Threads.svg';
import GPU from '../../images/GPU.svg';
import Buildings from '../../images/Buildings.svg';

export default function PerformanceSection() {
    const [titleVisible, setTitleVisible] = useState(false);
    const titleRef = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTitleVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (titleRef.current) observer.observe(titleRef.current);

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
            return undefined;
        };
        }, []);

    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine ref={titleRef} visible={titleVisible}>
                        {'built for'}
                    </TitleFirstLine>
                    <TitleSecondLine ref={titleRef} visible={titleVisible}>
                        {'performance'}
                    </TitleSecondLine>
                </TextWrapper>
                <FocusCardWrapper>
                    {[
                        { src: Threads, title: 'Multi-threaded', subtitle: 'The Engine handles computations in the background, ensuring the front-end remains fast and responsive.' },
                        { src: GPU, title: 'GPU-Accelerated', subtitle: 'Real-time photorealistic renders with over 10x faster processing compared to traditional methods.' },
                        { src: Buildings, title: 'Complex Models', subtitle: 'Handle thousands of buildings within the same project, allowing for quick and efficient masterplan layouts.' }
                    ].map((item, index) => (
                        <CardBox
                            key={index} focused={false}
                            showBackground={true}
                        >
                            <StyledImage src={item.src} alt={`OBX Dash mockup ${index + 1}`} />
                            <ImageTitle>{item.title}</ImageTitle>
                            <ImageSubtitle>{item.subtitle}</ImageSubtitle>
                            <OBXCTAButton text={'Learn More'} size={'small'} />
                        </CardBox>
                    ))}
                </FocusCardWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 250px;

    @media (max-width: 1233px) {
        padding-bottom: 100px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 70px;
    width: 85%;

    @media (max-width: 1233px) {
        gap: 30px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    gap: 20px;

    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 0px;
    }
`;

const TitleFirstLine = styled.h1<{ visible: boolean }>`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1<{ visible: boolean }>`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
    transition: opacity 1s ease, transform 1s ease;
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const ImageTitle = styled.h2`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin: 0;
`;

const ImageSubtitle = styled.h3`
    color: white;
    font-family: 'DM Sans', sans-serif;
    max-width: 80%;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 31px;
    margin: 0;
`;

const StyledImage = styled.img`
    height: 78.81px;
    border-radius: 23px;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 5;
`;
