import React, { useEffect, useRef, useState, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

interface FocusCardWrapperProps {
    children: ReactNode;
    gapPC?: string;
    gapMobile?: string;
}

export default function FocusCardWrapper({ children, gapPC = '46px', gapMobile = '0' }: FocusCardWrapperProps) {
    const [focusedIndex, setFocusedIndex] = useState<number>(0); // Start with the first element focused
    const cardRefs = useRef<Array<HTMLDivElement | null>>([]);

    const findClosestToCenter = () => {
        const centerY = window.innerHeight / 2;

        let closestIndex = -1;
        let smallestDistance = Infinity;

        cardRefs.current.forEach((card, index) => {
            if (card) {
                const rect = card.getBoundingClientRect();
                const cardCenterY = rect.top + rect.height / 2;
                const distanceToCenter = Math.abs(centerY - cardCenterY);

                if (distanceToCenter < smallestDistance) {
                    smallestDistance = distanceToCenter;
                    closestIndex = index;
                }
            }
        });

        setFocusedIndex(closestIndex);
    };

    useEffect(() => {
        const handleScroll = () => findClosestToCenter();
        window.addEventListener('scroll', handleScroll);

        // Initial check in case the user doesn't scroll
        findClosestToCenter();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <StyledFocusCardWrapper gapPC={gapPC} gapMobile={gapMobile}>
            {React.Children.map(children, (child, index) =>
                React.isValidElement(child)
                    ? React.cloneElement(child as ReactElement, {
                        ref: (el: HTMLDivElement) => (cardRefs.current[index] = el),
                        focused: focusedIndex === index,
                    })
                    : child
            )}
        </StyledFocusCardWrapper>
    );
}

const StyledFocusCardWrapper = styled.div<{ gapPC: string; gapMobile: string }>`
    display: flex;
    justify-content: center;
    gap: ${(props) => props.gapPC};
    padding-top: 10px;

    @media (max-width: 1233px) {
        flex-direction: column;
        gap: ${(props) => props.gapMobile};
    }
`;
